import React from 'react';
import {Container, KeyValuePair, Text} from "@aws-prototyping/northstar";

const About = () => (
    <Container title="About">
      <Text>UMM stands for Unintended Muttering Meter!</Text>
      <br /><br />
      <Text>This monitors presentations for unintended content, either in real-time or as a batch, giving you visual feedback and suggestions. It's fully automated and serverless!</Text>
    </Container>
);

export default About;
