import React, {useEffect, useRef} from 'react';
import uPlot from 'uplot';
import 'uplot/dist/uPlot.min.css';

export interface ChartProps {
  options: uPlot.Options;
  series: uPlot.AlignedData;
}

/**
 * Very simple wrapper so that uPlot can be used in react.
 */
const Chart: React.FC<ChartProps> = ({ options, series }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chart = new uPlot(options, series, ref.current || undefined);
    return () => chart.destroy();
  }, [options, series]);

  return <div ref={ref} />;
};

export default Chart;
