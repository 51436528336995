import React, {useMemo, useRef, useState} from 'react';
import {
  Alert,
  Button,
  Column,
  ColumnLayout,
  Container,
  Heading,
  Inline,
  LoadingIndicator,
  Text
} from "@aws-prototyping/northstar";
import { Auth, API } from 'aws-amplify';
import { v4 as uuid } from 'uuid';

import { start as startTranscribing } from '../utils/transcribe';
import TimeseriesChart, { generateColours, TimeseriesChartSeries} from "../components/TimeseriesChart";
import ColorLabel from "../components/ColorLabel";

const globalState = {
  previousResponse: undefined,
  close: undefined,
};

const Home = () => {
  const chartRef = useRef<any>();

  const [error, setError] = useState<string>();
  const [inProgress, setInProgress] = useState(false);
  const [started, setStarted] = useState(false);

  const [previousResponse, setPreviousResponse] = useState<any>({});
  const [fullTranscription, setFullTranscription] = useState<string>();
  const [latestTranscription, setLatestTranscription] = useState<string>();

  const [plotData, setPlotData] = useState<any[]>([]);


  const onResult = async (result: any) => {
    //console.log(result);

    if (!result.IsPartial) {
      //console.log('not partial, sending to umm api...');

      const response = await API.post('API', 'umm', {
        body: {
          transcribeResult: result,
          previousResponse: globalState.previousResponse,
        },
      });

      setPlotData((prev) => ([...prev, response.plotData]));

      /**
       * {
       *   timestamp: xxx
       *   exclusiveCount: xxx
       *   fillerCount: xxx
       *   exclusiveMatches: [ {
       *     'bad': 'good'
       *   } ],
       * }
       */

      setPreviousResponse({ ...response });
      globalState.previousResponse = response;
      setFullTranscription(prev => `${prev || ''} ${response.formattedTranscribe}`);

      //console.log('received response from umm api', response);

    }

    if (result.Alternatives.length > 0) {
      let transcript = result.Alternatives[0].Transcript;

      // fix encoding for accented characters
      transcript = decodeURIComponent(escape(transcript));
      setLatestTranscription(transcript);
    }


  };

  const setClose = (close: any) => { globalState.close = close};


  const onError = (result: any) => null;//setError(result);

  const buildSeries = (data: any[]): TimeseriesChartSeries[] => ([
    { name: 'Exclusive Words', data: data.map((d) => ([d.timestamp, d.exclusiveCount])) },
    { name: 'Filler Words', data: data.map((d) => ([d.timestamp, d.fillerCount]))  },
  ]);

  const start = async () => {
    const credentials = await Auth.currentCredentials();

    setError(undefined);
    if (!inProgress) {
      setStarted(true);
      // start listening
      try {
        startTranscribing(onResult, onError, setClose, credentials.accessKeyId, credentials.secretAccessKey, credentials.sessionToken, 'ap-southeast-2');
      } catch (e) {
        setError("Failed to start recording!");
      }
    } else {
      // @ts-ignore
      globalState.close && globalState.close();
    }
    setInProgress(!inProgress);
  };

  const reset = () => {
    setPreviousResponse(undefined);
    setFullTranscription(undefined);
    setLatestTranscription(undefined);
    setPlotData([]);
    setStarted(false);
  };

  const scoreStyle = (score: string) => {
    const numberScore = Number(score.replace('%', ''));
    let color = '#1d8102';

    if (numberScore < 90) {
      color = '#d13212';
    } else if (numberScore < 97) {
      color = '#fd6a02';
    }

    return ({
      fontSize: 30,
      color,
    });
  };

  const colors = generateColours(2);

  return (
      <>
        { error ? <Alert type="error" header={error} /> : null }
        <Heading variant="h1">Unintended Muttering Meter</Heading>
        <Container title="Live Presentation Analysis" actionGroup={<Inline><Button disabled={inProgress} onClick={reset}>Reset</Button><Button variant="primary" onClick={start}>{inProgress ? 'Stop' : 'Start'} Listening!</Button></Inline>}>
          {
            started && previousResponse && previousResponse.exclusiveScore ? (
                <>
                  <ColumnLayout>
                    <Column>
                      <ColorLabel color={colors[0]} label={<Heading variant="h2">Inclusivity</Heading>} />
                      <p style={scoreStyle(previousResponse.exclusiveScore)}>{previousResponse.exclusiveScore}</p>
                    </Column>
                    <Column>
                      <ColorLabel color={colors[1]} label={<Heading variant="h2">Fluency</Heading>} />
                      <p style={scoreStyle(previousResponse.fillerScore)}>{previousResponse.fillerScore}</p>
                    </Column>
                  </ColumnLayout>

                  <p style={{ fontSize: 18 }}>{latestTranscription}</p>

                  <div style={{ width: '100%' }} ref={chartRef}>
                    <TimeseriesChart id='chart' series={buildSeries(plotData)} colors={colors} width={chartRef && chartRef.current ? chartRef.current.offsetWidth : 800} extra={plotData} />
                  </div>

                  <Heading variant="h3">Full Transcript</Heading>
                  <p dangerouslySetInnerHTML={{ __html: fullTranscription || '' }} />
                </>
            ) : (
                inProgress ? (
                    <LoadingIndicator />
                ) : <Text>Click the button to the right to start recording!</Text>
            )
          }
        </Container>
      </>
  );
};

export default Home;
