import React from 'react';
import {Container, KeyValuePair, Text} from "@aws-prototyping/northstar";

const Contact = () => (
  <Container title="Contact Us">
    <Text>Made with ❤️ by Group 5 as part of the SA Launch Capstone Project in October 2020</Text>
    <br />
    <KeyValuePair label="Email" value={<a href={"mailto:group5@amazon.com"}>group5@amazon.com</a>} />
  </Container>
);

export default Contact;
