/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { makeStyles } from '@material-ui/core/styles';

import AppLayout from '@aws-prototyping/northstar/layouts/AppLayout';
import PrototypingUIThemeProvider from '@aws-prototyping/northstar/components/PrototypingUIThemeProvider';
import Box from '@aws-prototyping/northstar/layouts/Box';
import Header from '@aws-prototyping/northstar/components/Header';
import SideNavigation, { SideNavigationItemType } from '@aws-prototyping/northstar/components/SideNavigation';
import Inline from '@aws-prototyping/northstar/layouts/Inline';
import ButtonDropdown from '@aws-prototyping/northstar/components/ButtonDropdown';

import CognitoProvider, { useCognitoContext } from "../../context/cognito";
import Breadcrumb from "../../Breadcrumb";
import Home from "../Home";
import About from "../About";
import Contact from "../Contact";

const useStyles = makeStyles({
  headerContent: {
    '& .MuiButton-root': {
      border: 'none',
      padding: '4px 5px',
      color: 'currentColor',
      '&:hover': {
        color: 'currentColor'
      }
    }
  }
});

const App: React.FC = () => {
  const styles = useStyles();
  const { user, signOut } = useCognitoContext();

  const handleSignOut = () => {
    signOut();
    window.location.reload();
  };

  const headerDropdown = <ButtonDropdown content={user?.username} items={[{ text: 'Sign Out', onClick: handleSignOut }]} />;

  const headerContent = (
    <Box className={styles.headerContent} hidden={!user}>
      <Inline>{headerDropdown}</Inline>
    </Box>
  );

  const header = <Header title='' rightContent={headerContent} />;

  const navigationItems = [
    { type: SideNavigationItemType.LINK, text: 'Home', href: '/' },
    { type: SideNavigationItemType.LINK, text: 'About', href: '/about' },
    { type: SideNavigationItemType.LINK, text: 'Contact', href: '/contact' },
  ];

  const navigation = (
    <SideNavigation
      header={{
        href: '/',
        text: 'U.M.M.'
      }}
      items={navigationItems}
    />
  );

  const breadcrumbGroup = <Breadcrumb />;

  return (
    <PrototypingUIThemeProvider>
      <CognitoProvider>
        <Router>
          <AppLayout header={header} navigation={navigation} breadcrumbs={breadcrumbGroup}>
            <Switch>
              <Route path={"/about"} component={About} />
              <Route path={"/contact"} component={Contact} />
              <Route path={"/"} component={Home} />
            </Switch>
          </AppLayout>
        </Router>
      </CognitoProvider>
    </PrototypingUIThemeProvider>
  );
};

export default withAuthenticator(App);
