import uPlot from "uplot";
import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import {formatTimestamp} from "./TimeseriesChart";
import ColorLabel from "./ColorLabel";

export const renderComponentToHtml = (component: React.ReactElement) => {
  const div = document.createElement("div");
  ReactDOM.render(component, div);
  const htmlText = div.innerHTML;
  ReactDOM.unmountComponentAtNode(div);
  return htmlText;
};

/**
 * uPlot plugin for rendering a tooltip for timeseries charts
 */
export const tooltipPlugin = ({colors, extra}: any) => {
  let tooltipElement: HTMLDivElement;

  return {
    hooks: {
      init: (u: uPlot) => {
        // Create the initial tooltip element
        tooltipElement = document.createElement("div");
        tooltipElement.style.pointerEvents = "none";
        tooltipElement.style.position = "absolute";
        tooltipElement.style.background = "rgba(255, 255, 255, 0.8)";
        u.root.querySelector(".u-over")?.appendChild(tooltipElement);
      },
      setCursor: (u: any) => {
        const {left, top, idx} = u.cursor;

        // The index is null when the cursor is out of the bounds of all charts in the group
        tooltipElement.style.display = idx === null ? "none" : "block";

        // Position the tooltip a little to the right of the cursor
        tooltipElement.style.left = left + 10 + "px";
        tooltipElement.style.top = top + "px";

        if (idx !== null) {
          tooltipElement.innerHTML = renderComponentToHtml(
              <div style={{fontSize: 12}}>
                {
                  // Render a current value indicator for every series
                  u.data.map((data: Array<any>, i: number) => {
                    // First series is time
                    if (i == 0) {
                      return null;
                    }

                    // exclusive
                    if (i == 1) {
                      return (
                          <div>
                            {
                              extra[idx].exclusiveMatches.map((match: any) => (
                                  <ColorLabel key={i} size={10} color={_.get(colors, i - 1, 'black')}
                                              label={`You said ${Object.keys(match)[0]}. Suggestions: ${match[Object.keys(match)[0]].join(', ')}`}/>
                              ))
                            }
                          </div>
                      );
                    }

                    // filler
                    if (i == 2) {
                      return (
                          <div>
                          {
                            extra[idx].fillerMatches.map((word: any) => (
                                <ColorLabel key={i} size={10} color={_.get(colors, i - 1, 'black')}
                                            label={`You said: ${word}`} />
                            ))
                          }
                          </div>
                      );
                    }

                    return u.data.length > 2 ? (
                        <>
                          <ColorLabel key={i} size={18} color={_.get(colors, i - 1, 'black')}
                                      label={`${data[idx]?.toFixed(0)}`}/>
                        </>
                    ) : data[idx]?.toFixed(0)
                  })
                }
              </div>
          );
        }
      },
    },
  };
};