/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import React, { useEffect, useContext, createContext, useState } from "react";
import {Auth, Amplify} from "aws-amplify";

import LoadingIndicator from '@aws-prototyping/northstar/components/LoadingIndicator';

// @ts-ignore
const { region, userPoolId, userPoolWebClientId, identityPoolId, apiUrl } = window['runConfig'];

// Set up cognito auth, and configure our api endpoint
Amplify.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    identityPoolId,
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: apiUrl,
        region,
        service: 'execute-api',
      },
    ],
  },
});

type CognitoContextProps = {
  user: any,
  signOut: () => void,
  loading: boolean,
};

const useCognito = (): CognitoContextProps => {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  const signOut = () => {
    Auth.signOut();
  };

  const initializeUser = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    setUser(user);
    setLoading(false);
  };

  useEffect(() => {
    initializeUser();
  }, []);

  return { user, signOut, loading };
};

export const CognitoContext = createContext<CognitoContextProps>({
  user: {},
  loading: true,
  signOut: () => {},
});

interface CognitoProviderProps {
  children: React.ReactNode;
}

export const useCognitoContext = (): CognitoContextProps => {
  return useContext(CognitoContext);
};

const CognitoProvider: React.FC<CognitoProviderProps> = ({
  children,
}) => {
  const cognito = useCognito();

  if(cognito.loading) return <LoadingIndicator />

  return (
    <CognitoContext.Provider value={cognito}>
      {React.Children.only(children)}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
