import React from 'react';

export interface ColorLabelProps {
  color: string;
  label: React.ReactNode;
  size?: number;
}

const DEFAULT_CIRCLE_SIZE = 12;

const ColorLabel: React.FC<ColorLabelProps> = ({ color, label, size }) => (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{
        width: size || DEFAULT_CIRCLE_SIZE,
        height: size || DEFAULT_CIRCLE_SIZE,
        borderRadius: size || DEFAULT_CIRCLE_SIZE,
        marginTop: 5,
        marginRight: 3,
        backgroundColor: color,
      }} />
      {label}
    </div>
);

export default ColorLabel;
