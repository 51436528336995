import React, {useMemo} from "react";
import _ from 'lodash';
import Chart from './UPlotChartWrapper';
import {tooltipPlugin} from "./tooltipPlugin";


// Array of distinct colours to be used for drawing lines on charts
const COLOURS = [
  '#ff0000',
  '#fd6a02',
  '#46f0f0',
  '#f032e6',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#808080',
];

/**
 * Generates a list of n colours
 */
export const generateColours = (n: number) => _.times(n, (i) => COLOURS[i % COLOURS.length]);

export const formatTimestamp = (timestamp: string | number | undefined): string => {
  if (!timestamp) {
    return '-';
  }
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export interface TimeseriesChartSeries {
  name: string;
  data: Array<Array<number>>;
}

export interface TimeseriesChartProps {
  id: string;
  series: Array<TimeseriesChartSeries>;
  extra: Array<any>;
  chartName?: string;
  group?: string;
  colors?: Array<string>;
  showXLabels?: boolean;
  height?: number;
  width?: number;
}


/**
 * The timeseries chart is responsible for the display of timeseries data. Timestamps can either be absolute epoch millis
 * timestamps, or offsets from the given offsetStart (epoch millis).
 */
const TimeseriesChart: React.FC<TimeseriesChartProps> = ({
 id,
 series,
 chartName,
 group,
 colors,
 showXLabels,
 height,
 width,
 extra,
}) => {
  // Convert the series into the columnar format expected by uPlot
  const data = useMemo(() => {
    const timestamps = series[0].data.map((d) => d[0]);
    const yValues = _.times(series.length, (i) => series[i].data.map((d) => d[1]));
    return [timestamps, ...yValues];
  }, [series]);

  if (data.length === 0 || data[0].length === 0) {
    return <p>No data</p>;
  }

  const options = {
    id,
    height: height || 200,
    width: width || 400,
    title: chartName,
    plugins: [
      tooltipPlugin({ colors, extra }),
    ],
    cursor: {
      lock: true,
      y: false,
      focus: {
        prox: 16,
      },
      ...(group ? { sync: {
          key: group,
          setSeries: true,
        } } : {}),
    },
    legend: {
      show: true,
    },
    series: [
      {
        label: 'Time',
        scale: 'timescale',
        value: (u: any, timestamp: number) => formatTimestamp(timestamp)
      },
      ...series.map((s, i) => ({
        label: s.name,
        value: (u: any, v: any) => v == null ? "-" : v.toFixed(1),
        stroke: colors ? colors[i] : undefined,
        scale: 'dataScale',
        width: 3,
      })),
    ],
    scales: {
      timescale: {
        time: true,
        auto: true,
      },
      dataScale: {
        time: false,
        auto: true,
      },
    },
    axes: [
      {
        show: showXLabels,
        scale: 'timescale',
      },
      {
        size: 70,
        values: (u: any, vals: any) => vals.map((v: any) => v?.toFixed(0)),
        scale: 'dataScale',
      }
    ],
  };

  return <Chart series={data} options={options} />;
};

export default TimeseriesChart;
